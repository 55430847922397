<template>
  <HybridPopper
    class="chip-more-container"
    ref="popper"
    placement="bottom-end"
    @open:popper="opened = true"
    @close:popper="opened = false"
    offset-distance="8"
    @click.stop
  >
    <Chip :big="big" :selected="opened" :icon="small">{{ small ? `еще ${skills.length}` : `И еще ${skills.length}...` }}</Chip>
    <template #content>
      <div class="chip-popup">
        <Chip v-for="skill in skills" :key="skill.id" not-clickable :title="skill.skill_name ?? skill.name" :icon="small">{{ skill.skill_name ?? skill.name }}</Chip>
      </div>
    </template>
  </HybridPopper>
</template>

<script setup>
import Chip from '@/components/common/Chip'

const props = defineProps({
  big: {
    type: Boolean,
    default: false
  },
  skills: {
    type: Array,
    required: true
  },
  small: Boolean
})

const opened = ref(false)
</script>

<style lang="scss">
.chip-more-container .popper {
  padding: 8px !important;
}
</style>

<style scoped lang="scss">
.chip-popup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  & > :deep(*) {
    flex-grow: 1;
  }
}
</style>
